import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  component,
  Button,
  Card,
  Input,
} from '@k-mille/ui-components'
import styles from './project.styles'
import Logo from './logo'

const getInputType = (gqlType) => {
  switch (gqlType) {
    case 'Float': return 'number'
    case 'Color': return 'color'
    default: return 'text'
  }
}

const graphqlToInput = (prefix = '') => (field) => {
  const properties = {
    name: `${prefix}${field.replace(/:.*/, '')}`,
    label: `${prefix}${field.replace(/:.*/, '')}`,
    placeholder: `${prefix}${field.replace(/:.*/, '')}`,
    type: getInputType(field.replace(/.*: /, '').replace('!', '')),
  }

  if (field.includes('!')) properties.required = true

  return properties
}

const fields = [ // this are GQL types
  'code: String!',
  'name: String!',
  'type: String!',
  'siren: String',
  'intraCommunityTvaNumber: String',
  'capital: Float',
  'address: String!',
  'city: String!',
  'postalCode: String!',
  'bic: String',
  'iban: String',
  'invoicesGoogleSheetId: String',
  'invoicesGoogleSheetRange: String',
  'invoicesGoogleFolderId: String',
  'expensesGoogleSheetId: String',
  'expensesGoogleSheetRange: String',
].map(graphqlToInput())

const colorFields = [
  'primaryColor: Color',
  'secondaryColor: Color',
].map(graphqlToInput())

const Project = ({
  classes,
  messages,
  code,
  onSubmit,
}) => (
  <Card
    className={classes.main}
    onSubmit={onSubmit}
  >
    <Card.Header>
      {messages.title}
    </Card.Header>
    <Card.Content className={classes.content}>
      <Logo
        className={classes.logo}
        alt={code}
      />
      <div className={classes.info}>
        {fields.map(field => (
          <Input
            className={cn(
              classes[field.name],
              classes.input,
            )}
            key={field.name}
            filled
            formName="project"
            {...field}
          />
        ))}

        <div className={classes.colors}>
          {colorFields.map(field => (
            <Input
              className={cn(
                classes.colorInput,
                classes.input,
              )}
              key={field.name}
              filled
              formName="project"
              {...field}
            />
          ))}
        </div>
      </div>
    </Card.Content>
    <Card.Actions>
      <Button
        type="submit"
        primary
      >
        Save
      </Button>
      <a
        className={classes.googleLink}
        href={`${process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : ''}/api/auth/google`}
        title="Link your google account, so you can use spreadsheet exports."
      >
        <span>
          (Re) Link your google account
        </span>
        <img
          className={classes.googleLogo}
          src="/google.png"
          alt="google"
        />
      </a>
    </Card.Actions>
  </Card>
)

Project.propTypes = {
  classes: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  code: PropTypes.string,
}

Project.defaultProps = {
  code: undefined,
}

export default component({ styles })(Project)
