export const load = (action, store, { graphql }) => {
  const QUERY_CLIENTS = `clients {
    id
    name
    address
    city
    postalCode
  }`

  const QUERY_USERS = `users {
    id
    name
    firstName
    email
  }`

  const QUERY_PROJECT = `projects {
    id
    code
    name
    address
    postalCode
    city
    type
    intraCommunityTvaNumber
    siren
    capital
    bic
    iban
    logo
    parameters {
      invoicesGoogleSheetId
      invoicesGoogleSheetRange
      invoicesGoogleFolderId
      expensesGoogleSheetId
      expensesGoogleSheetRange
      primaryColor
      secondaryColor
    }
  }`

  graphql.query(`
    ${QUERY_CLIENTS}
    ${QUERY_USERS}
    ${QUERY_PROJECT}
  `)
}

export const setClients = ({ payload }, store, { form }) => {
  [].concat(payload).forEach((client) => {
    form(`client-${client.id}`).set(client)
  })
  form.reset('client')
}

export const savePassword = (action, store, { form, graphql }) => {
  const { email } = store.data.profile.info.get()
  const password = form('password').get()

  const MUTATION_PASSWORD = `
    ReplacePassword($password: InputReplacePassword!) {
      replacePassword(input: $password)
    }
  `

  graphql.mutation(
    MUTATION_PASSWORD,
    {
      password: {
        ...password,
        email,
      },
    },
  )
}

export const setPasswordError = (action, store, { form }) => {
  form('password').reset()
  form('password').setErrors({ password: 'Can\'t login' })
}

export const clearPasswordErrors = (action, store, { form }) => {
  form('password').resetErrors()
}

export const logout = (action, store, { login }) => {
  login.logout()
}

export const setProject = ({ payload }, store, { form, router }) => {
  if (payload.length === 0) return
  const connectedProject = store.data.profile.project.get()
  const foundProject = payload.find(({ code }) => code === connectedProject.code)

  if (!foundProject) {
    console.error('Can not find the connected project into the returned from the API')
    console.error('\treturned projects', payload.map(({ code }) => code))
    console.error('\tconnected project', connectedProject.code)
    router.push('login')
    return
  }

  const { parameters, ...project } = foundProject
  form('project').set({ ...project, ...parameters })
}

export const saveProject = (action, store, { form, graphql }) => {
  const {
    invoicesGoogleSheetId,
    invoicesGoogleSheetRange,
    invoicesGoogleFolderId,
    expensesGoogleSheetId,
    expensesGoogleSheetRange,
    primaryColor,
    secondaryColor,
    ...project
  } = form('project').get()

  const MUTATION_PROJECT = `
    ReplaceProject($project: InputReplaceProject!) {
      replaceProject(input: $project) {
        id
      }
    }
  `

  const newProject = {
    ...project,
    parameters: {
      invoicesGoogleSheetId,
      invoicesGoogleSheetRange,
      invoicesGoogleFolderId,
      expensesGoogleSheetId,
      expensesGoogleSheetRange,
      primaryColor,
      secondaryColor,
    },
  }
  if (store.ui.uploadedLogo.isInitialized()) {
    newProject.logo = store.ui.uploadedLogo.get()
    newProject.logo.data = newProject.logo.data.replace(/data:.*;base64,/, '')
  } else {
    newProject.logo = undefined
  }

  graphql.mutation(
    MUTATION_PROJECT,
    {
      project: newProject,
    },
  )
}

export const setUsers = ({ payload }, store) => {
  store.data.users.set(payload)
}

export const saveClient = ({ payload }, store, { graphql, form }) => {
  const client = form(payload.id || 'client').get()

  const MUTATION_ADD_CLIENT = `
    AddClient($client: InputClient!) {
      addClient(input: $client) {
        id
        name
        address
        city
        postalCode
      }
    }
  `

  const MUTATION_REPLACE_CLIENT = `
    ReplaceClient($client: InputReplaceClient!) {
      replaceClient(input: $client) {
        id
      }
    }
  `

  graphql.mutation(
    client.id ? MUTATION_REPLACE_CLIENT : MUTATION_ADD_CLIENT,
    {
      client,
    },
  )
}

export const updateClientId = ({ payload }, store) => {
  const client = store.data.clients.get('new')
  store.data.clients.remove('new')
  store.data.clients.add({ ...client, id: payload.id })
}

// TODO: use image driver
const getBase64 = file => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const setUploadedLogo = async ({ payload }, store) => {
  if (!payload) return
  const { name, type } = payload
  const data = await getBase64(payload)

  store.ui.uploadedLogo.set({ data, name, type })
}
